.register-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url('./../Images/RegisterBackground.jpg') no-repeat center center fixed;
  background-size: cover;
  position: relative;
}

.register-back-button-container {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 10;
}

.register-container {
  display: flex;
  max-width: 995px;
  width: 100%;
  height: 640px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  background: white;
}

.register-image {
  flex: 1;
  background: url('./../Images/RegisterImage.jpg') no-repeat center center;
  background-size: cover;
  position: relative;
}

.register-image img.logo {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 80px;
}

.register-form {
  flex: 1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.register-form h2 {
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #333;
}

.register-form-group {
  margin-bottom: 15px;
  width: 100%;
}

.register-form-group label {
  margin-bottom: 10px;
  display: block;
  color: #333;
  text-align: left;
}

button {
  padding: 10px;
  width: 100%;
  background-color: #2e7344;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  font-size: 1.1rem;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #3a9f5a;
}

@media (max-width: 768px) {
  .register-container {
    flex-direction: column;
    height: auto;
    max-width: 100%;
    margin: 0 20px;
  }

  .register-image {
    width: 100%;
    height: 200px;
  }

  .register-form {
    padding: 20px;
  }

  .register-form h2 {
    font-size: 1.25rem;
  }

  .back-button-container {
    top: 10px;
    left: 10px;
  }
}

@media (max-width: 480px) {
  .register-form h2 {
    font-size: 1rem;
  }

  .register-form {
    padding: 15px;
  }

  .register-form-group label {
    margin-bottom: 5px;
  }

  .back-button-container {
    top: 5px;
    left: 5px;
  }

  .register-container {
    margin: 0 10px;
  }
}
