.formulario-pat-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  overflow-x: hidden;
  position: relative;
}

.logo-izquierda {
  position: absolute;
  top: 80px;
  left: 40px;
  width: 120px;
}

.logo-derecha {
  position: absolute;
  top: 90px;
  right: 10px;
  width: 220px;
}

.formulario-pat-form {
  width: 80%;
  max-width: 800px;
  margin: 0 auto;
}

.formulario-pat-back-button-container {
  position: absolute;
  top: 90px;
  left: 10px;
  z-index: 10;
}

.columna {
  margin-bottom: 20px;
}

.columna label {
  color: rgb(0, 0, 0);
  display: block;
  margin-bottom: 10px;
  margin-top: 8px;
}

textarea,
select,
input[type="number"] {
  resize: none;
  width: 95%;
  padding: 8px;
  margin-bottom: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  padding: 10px 20px;
  margin-bottom: 25px;
  font-size: 16px;
  background-color: #2E7344;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #3a9f5a;
}

.MuiAccordion-root {
  margin-bottom: 20px;
}

.MuiAccordionSummary-root {
  background-color: #f1f1f1;
}

.formulario-pat-buttons {
  display: flex;
  justify-content: space-between; 
  margin-top: 10px; 
}

.formulario-pat-add-activity,
.formulario-pat-delete-activity {
  flex: 1; 
  margin-right: 10px; 
}

.formulario-pat-add-activity button,
.formulario-pat-delete-activity button {
  width: 90%; 
}

@media (max-width: 1024px) {
  .formulario-pat-back-button-container {
    display: none;
  }
  .logo-izquierda,
  .logo-derecha {
    display: none;
  }

  .formulario-pat-form {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .formulario-pat-back-button-container {
    display: none;
  }
  .logo-izquierda,
  .logo-derecha {
    display: none;
  }

  .formulario-pat-form {
    width: 95%;
  }

  textarea,
  select,
  input[type="number"] {
    font-size: 12px;
    padding: 6px;
  }

  button {
    padding: 8px 16px;
    font-size: 14px;
  }

  .MuiAccordionSummary-root {
    font-size: 14px;
  }
}