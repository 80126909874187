.ejes-rectores-page {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f0f0f0;
  overflow-x: hidden;
}

.ejes-rectores-back-button-container {
  position: absolute;
  top: 5.5rem;
  left: 0.5rem;
  z-index: 10;
}

.ejes-rectores-content-container {
  width: 90%;
  margin: 0 auto;
  padding: 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

h2 {
  font-size: 20px;
  margin: 20px 0;
  text-align: left;
}

.ejes-rectores-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.ejes-rectores-card {
  width: 45%;
  height: 100px;
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  transition: transform 0.2s, box-shadow 0.2s;
}

.ejes-rectores-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 16px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.ejes-rectores-card strong {
  display: block;
  font-size: 16px;
  margin-bottom: 5px;
}

.ejes-rectores-card p {
  font-size: 20px;
  color: #333;
}

@media (max-width: 768px) {
  .ejes-rectores-back-button-container {
    display: none;
  }
  h1 {
    font-size: 22px;
  }
  h2 {
    font-size: 18px;
  }
  .ejes-rectores-card {
    width: 100%;
    margin: 10px 0;
  }
  .ejes-rectores-card p {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .ejes-rectores-back-button-container {
    display: none;
  }
  h1 {
    font-size: 20px;
  }
  h2 {
    font-size: 16px;
  }
  .ejes-rectores-card {
    width: 100%;
    margin: 10px 0;
    height: auto;
  }
  .ejes-rectores-card p {
    font-size: 16px;
  }
}