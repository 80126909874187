.directory-header {
  min-width: 0;
  padding: 20px;
  background-color: #6F1F35;
  color: white;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.user-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  width: 100%;
}

.user-info span {
  font-size: 1.22rem;
}

.logout-container {
  margin-left: auto;
}

.logout-button {
  padding: 8px;
}

@media (max-width: 1024px) {
  .user-info span {
    font-size: 1rem; 
  }
}

@media (max-width: 768px) {
  .user-info span {
    font-size: 0.9rem; 
  }
}