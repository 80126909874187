.ver-pat-container {
  width: 100%;
  min-height: 100vh; 
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  overflow-x: hidden;
  position: relative;
}

.logo-izquierda {
  position: absolute;
  top: 80px;
  left: 40px;
  width: 120px;
}

.logo-derecha {
  position: absolute;
  top: 90px;
  right: 10px;
  width: 220px;
}

.ver-pat-back-button-container {
  position: absolute;
  top: 90px;
  left: 10px;
  z-index: 10;
}

.formulario-pat-title {
  text-align: center;
  margin: 20px 0;
}

.ver-pat-content {
  display: flex;
  align-items: flex-start;
  flex-grow: 1;
  padding: 10px;
  margin-top: -10px;
  overflow-x: auto; 
}

.pat-grid {
  display: flex; 
  flex-wrap: wrap; 
  gap: 20px;
  justify-content: flex-start; 
}

.calendario-realizado {
  display: flex;
  gap: 20px;
  width: 100%;
}

.trimestre-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.pat-category {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 8px;
  width: 100%;  
}

.pat-category h3 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 18px;
  color: #333;
  text-align: justify;
  flex-basis: 100%;
}

.pat-grid-item {
  background-color: #f4f4f4;
  padding: 15px;
  margin-top: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  text-align: left;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  flex: 1 1 100%; 
  max-width: 300px;
}

.pat-grid-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.actividad {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  flex: 1 1 300px; 
  margin: 20px;
}

.pat-accordion {
  background-color: #f0f0f0;
  margin-top: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  max-width: 300px;
  width: 100%; 
  box-sizing: border-box;
}

.pat-accordion:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.pat-accordion-summary {
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 15px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.pat-accordion-details {
  padding: 15px;
}

.pat-grid button {
  width: 180px; 
  padding: 10px 10px; 
  font-size: 13px;
  background-color: #2e7344;
  color: white;
  border: none;
  cursor: pointer;
}

.pat-grid button:hover {
  background-color: #3a9f5a;
}

@media (max-width: 768px) {
  .ver-pat-back-button-container {
    display: none; 
  }
}