.resetPassword-page {
  width: 100%;
  height: 100vh;
  background: url("./../Images/ForgotPBackground.jpg") no-repeat center center
    fixed;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.resetPassword-container {
  display: flex;
  max-width: 900px;
  width: 100%;
  height: 550px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  background: white;
  text-align: justify;
}

.resetPassword-image {
  flex: 1;
  background: url("./../Images/ResetPasswordImage.jpg") no-repeat center center;
  background-size: cover;
  position: relative;
}

.resetPassword-form {
  flex: 1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.resetPassword-form h2 {
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #333;
}

.resetPassword-form-group {
  position: relative;
  margin-bottom: 15px;
}

.resetPassword-form-group input[type="password"] {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  font-size: 1rem;
  box-sizing: border-box;
}

button {
  padding: 10px;
  width: 100%;
  background-color: #2e7344;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  font-size: 1.1rem;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #3a9f5a;
}

@media (max-width: 768px) {
  .resetPassword-container {
    flex-direction: column;
    height: auto;
    max-width: 100%;
    margin: 0 20px;
  }

  .resetPassword-image {
    width: 100%;
    height: 200px;
  }

  .resetPassword-form {
    padding: 20px;
  }

  .resetPassword-form h2 {
    font-size: 1.25rem;
  }
}

@media (max-width: 480px) {
  .resetPassword-form h2 {
    font-size: 1rem;
  }

  .resetPassword-form {
    padding: 15px;
  }

  .resetPassword-container {
    margin: 0 10px;
  }
}
