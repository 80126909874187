.login-page {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('./../Images/LoginBackground.jpg') no-repeat center center fixed;
  background-size: cover;
  position: relative;
}

.login-back-button-container {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 10;
}

.login-container {
  display: flex;
  max-width: 900px;
  width: 100%;
  height: 550px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  background: white;
}

.login-image {
  flex: 1;
  background: url('./../Images/LoginImage.jpg') no-repeat center center;
  background-size: cover;
  position: relative;
}

.login-form {
  flex: 1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-form h2 {
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #333;
}

.login-form-group {
  position: relative;
  margin-bottom: 15px;
}

.login-form-group label {
  margin-bottom: 10px;
  display: block;
  color: #333;
  text-align: left;
}

.login-form-group input[type="email"],
.login-form-group input[type="password"] {
  padding: 10px 30px 10px 40px; 
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  font-size: 1rem;
}

.login-form-group .icon {
  position: absolute;
  left: 10px; 
  top: 50%;
  transform: translateY(-50%);
  color: #aaa; 
}

.login-form button {
  padding: 10px;
  width: 100%;
  background-color: #6F1F35;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  font-size: 1.1rem;
  transition: background-color 0.3s ease;
}

.login-form button:hover {
  background-color: #892641;
}

.login-form-links {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  width: 100%;
}

.login-form-links a {
  color: #170fad;
  text-decoration: none;
}

.login-form-links a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .login-container {
    flex-direction: column;
    height: auto;
    max-width: 100%;
    margin: 0 20px;
  }

  .login-image {
    width: 100%;
    height: 200px;
  }

  .login-form {
    padding: 20px;
  }

  .login-form h2 {
    font-size: 1.25rem;
  }
}

@media (max-width: 480px) {
  .login-form h2 {
    font-size: 1rem;
  }

  .login-form {
    padding: 15px;
  }

  .login-form-group label {
    margin-bottom: 5px;
  }

  .login-container {
    margin: 0 10px;
  }
}
