.forgotPassword-page {
    width: 100%;
    height: 100vh;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    background: url('./../Images/ForgotPBackground.jpg') no-repeat center center fixed;
    background-size: cover;
    position: relative;
}
  
.forgotPassword-back-button-container {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 10;
}
  
.forgotPassword-container {
    display: flex;
    max-width: 900px;
    width: 100%;
    height: 550px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    background: white;
    text-align: justify;
}
  
.forgotPassword-image {
    flex: 1;
    background: url('./../Images/ForgotP.jpg') no-repeat center center;
    background-size: cover;
    position: relative;
}
  
.forgotPassword-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
  
.forgotPassword-form {
    flex: 1;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
  
.forgotPassword-form h2 {
    margin-bottom: 20px;
    font-size: 1.5rem;
    color: #333;
}
  
.forgotPassword-form p {
    margin-bottom: 20px;
    font-size: 1rem;
    color: #666;
}
  
.forgotPassword-form-group {
    position: relative;
    margin-bottom: 15px;
}
  
.forgotPassword-form-group input[type="email"] {
    padding: 10px 30px 10px 40px; 
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    font-size: 1rem;
}
  
.forgotPassword-form-group .icon {
    position: absolute;
    left: 10px; 
    top: 50%;
    transform: translateY(-50%);
    color: #aaa; 
}
  
.forgotPassword-form button {
    padding: 10px;
    width: 100%;
    background-color: #2e7344;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
    font-size: 1.1rem;
    transition: background-color 0.3s ease;
}
  
.forgotPassword-form button:hover {
    background-color: #3a9f5a;
}
  
@media (max-width: 768px) {
    .forgotPassword-container {
      flex-direction: column;
      height: auto;
      max-width: 100%;
      margin: 0 20px;
}
  
.forgotPassword-image {
      width: 100%;
      height: 200px;
}
  
.forgotPassword-form {
      padding: 20px;
}
  
.forgotPassword-form h2 {
      font-size: 1.25rem;
}
}
  
@media (max-width: 480px) {
    .forgotPassword-form h2 {
      font-size: 1rem;
}
  
.forgotPassword-form {
      padding: 15px;
}
  
.forgotPassword-container {
      margin: 0 10px;
}
}
  