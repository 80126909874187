.directory-page {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f0f0f0;
  overflow-x: hidden;
}

.directory-content {
  flex: 1;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.card {
  width: 300px;
  height: 480px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: center;
  margin: 10px;
  padding: 10px;
  transition: transform 0.2s, box-shadow 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 16px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.card h2 {
  font-size: 16px;
  margin: 10px 0;
}

.card h3 {
  font-size: 14px;
  color: #333;
  margin-bottom: 10px;
}

.card img {
  width: 98%;
  height: 390px;
  object-fit: cover;
  border-radius: 10px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .directory-content {
    justify-content: center;
  }
  .card {
    width: 45%;
    margin: 10px;
  }
  .card img {
    height: 300px;
  }
}

@media (max-width: 480px) {
  .card {
    width: 90%;
    margin: 10px 0;
  }
  .card img {
    height: 400px;
  }
  .card h2 {
    font-size: 14px;
  }
  .card h3 {
    font-size: 12px;
  }
}