.avance-mir-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  overflow-x: hidden;
  position: relative;
}

.logo-izquierda {
  position: absolute;
  top: 80px;
  left: 40px;
  width: 120px;
}

.logo-derecha {
  position: absolute;
  top: 90px;
  right: 10px;
  width: 220px;
}

.mir-table-row {
  display: contents;
}

.mir-table-header {
  display: contents;
}

.mir-grid-header {
  background-color: #ebeaea;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px 8px 0 0;
  color: #333;
}

.mir-table {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  width: 100%;
}

.mir-table-renglones {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  width: 100%;
}

.mir-table-renglones-avance {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  width: 100%;
  margin-bottom: -30px;
}

.avance-mir-back-button-container {
  position: absolute;
  top: 90px;
  left: 10px;
  z-index: 10;
}

.avance-mir-title {
  text-align: center;
  margin: 20px 0;
}

.avance-mir-content {
  display: flex;
  align-items: flex-start;
  flex-grow: 1;
  padding: 10px;
  margin-top: -10px;
  overflow-x: auto;
}

.avance-mir-grid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;
}

.mir-category {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.1);
  margin-top: -20px;
  width: 100%;
}

.avance-mir-actividades {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 6px 6px #0000001a;
  margin-bottom: 8px;
  width: 100%;  
}

.avance-mir-actividades h3 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 18px;
  color: #333;
  text-align: center;
  flex-basis: 100%;
}

.mir-grid-item {
  background-color: #ffffff;
  text-align: justify;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 0 0 8px 8px;
  color: #555;
}

.mir-grid-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.actividad {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  flex: 1 1 300px;
  margin: 20px;
}

.mir-accordion {
  background-color: #f0f0f0;
  margin-top: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  max-width: 415px;
  width: 100%;
  box-sizing: border-box;
}

.mir-accordion:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.mir-accordion-summary {
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 15px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.mir-accordion-details {
  padding: 15px;
}

.avance-mir-grid button {
  width: 180px;
  padding: 10px 10px;
  font-size: 13px;
  background-color: #6f1f35;
  color: white;
  border: none;
  cursor: pointer;
}

.avance-mir-grid button:hover {
  background-color: #892641;
}

@media (max-width: 768px) {
  .avance-mir-content {
    flex-direction: column;
    align-items: stretch;
  }

  .mir-grid {
    flex-direction: column;
  }
  .avance-mir-back-button-container {
    display: none;
  }
  
  .mir-category {
    width: 100%;
  }

  .mir-table {
    grid-template-columns: 1fr;
  }

  .mir-table-renglones,
  .mir-table-renglones-avance,
  .mir-table-responsable {
    grid-template-columns: 1fr;
  }

  .logo-izquierda,
  .logo-derecha {
    display: none;
  }

}
