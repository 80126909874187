@font-face {
  font-family: 'Bitter';
  src: url('./../Fonts/Bitter-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.home-container {
  position: relative; 
  text-align: center;
  min-height: 100vh; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header {
  position: fixed; 
  top: 0;
  left: 0; 
  width: 100%;
  padding: 20px;
}

.header-right {
  margin-top: -7px;
  display: flex;
  gap: 15px;
  justify-content: flex-end;
}

.header-link {
  cursor: pointer;
  color: black;
  font-size: 1rem;
  text-decoration: none;
  padding-left: 3.8px;
}

.header-link:last-child {
  margin-right: 45px; 
}

.home-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: url('./../Images/Home.jpg') no-repeat center center fixed;
  background-size: cover;
}

.main-content {
  color: #fff;
  text-align: center;
}

.logo {
  width: 60%; 
  max-width: 200px; 
}

.sistema-title {
  color: black; 
  font-size: 2.5rem;
  font-family: 'Bitter', serif;
  margin-bottom: 10px;
}

.sistema-text {
  color: black; 
  font-size: 1.3rem;
  font-family: 'Bitter', serif;
  font-weight: bolder;
}

.separator {
  width: 80%; 
  margin: 20px auto; 
  border: none; 
  border-top: 1px solid black;
}

@media only screen and (max-width: 600px) {
  .logo {
    width: 80%;
  }

  .sistema-title {
    font-size: 1.5rem;
  }

  .sistema-text {
    font-size: 0.9rem;
  }

  .separator {
    width: 90%;
  }
}