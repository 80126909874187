.formulario-mir-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    overflow-x: hidden;
    position: relative;
  }
  
  .logo-izquierda {
    position: absolute;
    top: 80px;
    left: 40px;
    width: 120px;
  }
  
  .logo-derecha {
    position: absolute;
    top: 90px;
    right: 10px;
    width: 220px;
  }
  
  .formulario-mir-form {
    width: 80%;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .formulario-mir-back-button-container {
    position: absolute;
    top: 90px;
    left: 10px;
    z-index: 10;
  }
  
  .columna {
    margin-bottom: 20px;
  }
  
  
  .columna label {
    color: rgb(0, 0, 0);
    display: block;
    margin-bottom: 10px;
    margin-top: 8px;
  }
  
  textarea,
  select,
  input[type="number"] {
    resize: none;
    width: 95%;
    padding: 8px;
    margin-bottom: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  button {
    padding: 10px 20px;
    margin-bottom: 25px;
    font-size: 16px;
    background-color: #6F1F35;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #892641;
  }
  
  .MuiAccordion-root {
    margin-bottom: 20px;
  }
  
  .MuiAccordionSummary-root {
    background-color: #f1f1f1;
  }
  
  .formulario-mir-buttons {
    display: flex;
    justify-content: space-between; 
    margin-top: 10px; 
  }
  
  .formulario-mir-add-activity,
  .formulario-mir-delete-activity {
    flex: 1; 
    margin-right: 10px; 
  }
  
  .formulario-mir-add-activity button,
  .formulario-mir-delete-activity button {
    width: 90%; 
  }

  .formulario-mir-buttons {
    display: flex;
    justify-content: space-between;
    gap: 20px; 
    margin-top: 15px; 
  }
  
  .btn {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    background-color: #6F1F35;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .btn:hover {
    background-color: #892641;
  }
    
  @media (max-width: 1024px) {
    .formulario-mir-back-button-container {
      display: none;
    }
    .logo-izquierda,
    .logo-derecha {
      display: none;
    }
  
    .formulario-mir-form {
      width: 90%;
    }
  }
  
  @media (max-width: 768px) {
    .formulario-mir-back-button-container {
      display: none;
    }
    .logo-izquierda,
    .logo-derecha {
      display: none;
    }
  
    .formulario-mir-form {
      width: 95%;
    }
  
    textarea,
    select,
    input[type="number"] {
      font-size: 12px;
      padding: 6px;
    }
  
    button {
      padding: 8px 16px;
      font-size: 14px;
    }
  
    .MuiAccordionSummary-root {
      font-size: 14px;
    }
  }