.admin-panel {
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: #f0f2f2;
  overflow-x: hidden;
}

.sidebar {
  width: 200px;
  background-color: #ffffff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.logo-panel-admin-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.logoPanelAdmin {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.sidebar h2 {
  margin-bottom: 20px;
}

.sidebar nav ul {
  list-style: none;
  padding: 0;
  width: 100%;
}

.sidebar nav ul li {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-bottom: 15px;
}

.sidebar nav ul li:hover {
  background-color: #e0e0e0;
  transform: translateY(-2px);
}

.sidebar-icon {
  color: green;
  margin-right: 10px;
  width: 50px;
}

.arrow-icon {
  margin-left: 15px;
}

.admin-main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.content {
  padding: 20px;
}

.flex-container {
  display: flex;
  gap: 20px;
}

.left-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
}

.date-picker-box,
.view-pat-box {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  flex: 1;
  max-height: 300px; 
  overflow-y: auto; 
}

.view-pat-box li {
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: justify;
  margin-bottom: 8px;
}

.date-picker-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
}

.date-picker-container .MuiTextField-root {
  width: 100%;
}

.date-picker-box .MuiButton-root {
  width: 100%;
  background-color: #2e7344;
  color: #ffffff;
}

.date-picker-box .MuiButton-root:hover {
  background-color: #45a049;
}

.user-list-box {
  display: flex;
  flex-direction: column;
  width: 60%;
  height: 580px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
  margin-top: au;
  overflow-y: auto;
}

.search-bar-container {
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

th {
  background-color: #f4f4f4;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
}

.loading-container p {
  margin-top: 10px;
  color: #4caf50;
}

.submenu {
  list-style-type: none;
  padding-left: 20px;
}

.submenu li {
  padding: 5px 0;
}