.ejes-rectores-mir-page {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f0f0f0;
  overflow-x: hidden;
}

.ejes-rectores-mir-back-button-container {
  position: absolute;
  top: 5.5rem;
  left: 0.5rem;
  z-index: 10;
}

.ejes-rectores-mir-content-container {
  width: 90%;
  margin: 0 auto;
  padding: 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

h2 {
  font-size: 20px;
  margin: 20px 0;
  text-align: left;
}

.ejes-rectores-mir-card-container {
  display: flex;
  flex-direction: column;
  gap: 10px; 
  align-items: stretch;
}

.ejes-rectores-mir-card {
  width: 100%; 
  height: auto; 
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  transition: transform 0.2s, box-shadow 0.2s;
}

.ejes-rectores-mir-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 16px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.ejes-rectores-mir-card strong {
  display: block;
  font-size: 16px;
  margin-bottom: 5px;
}

.ejes-rectores-mir-card p {
  font-size: 20px;
  color: #333;
}

@media (max-width: 768px) {
  .ejes-rectores-mir-back-button-container {
    display: none;
  }
  h1 {
    font-size: 22px;
  }
  h2 {
    font-size: 18px;
  }
  .ejes-rectores-mir-card {
    width: 100%;
    margin: 10px 0;
  }
  .ejes-rectores-mir-card p {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .ejes-rectores-mir-back-button-container {
    display: none;
  }
  h1 {
    font-size: 20px;
  }
  h2 {
    font-size: 16px;
  }
  .ejes-rectores-mir-card {
    width: 100%;
    margin: 10px 0;
    height: auto;
  }
  .ejes-rectores-mir-card p {
    font-size: 16px;
  }
}